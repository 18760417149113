import React, { useState } from "react"
import Layout from "@app-components/layout"
import Background from "@app-components/utils/background"
import Plans from "@page-components/products/festivals/pricing/plans/"
import Navigation from "@app-components/ui/header/components/navigation"
import HEADERS from "@data/headers/"
import FestivalsSignupModal from "@page-components/festivals/signup-modal"

const Pricing = () => {
  const [showModal, setShowModal] = useState(false)
  return (
    <Layout getStartedButtons={["demo", "get-started"]} page="products/festivals/pricing">
      <Background className="bg-cover bg-no-repeat bg-center" tagName="section" type="red-single">
        <Navigation navigation={HEADERS.festivals} extraHeight />
        <div
          style={{
            paddingTop: "12rem",
            paddingBottom: "14rem",
            marginBottom: "-13rem"
          }}
          className="white center bg-cover px2 md-px3 md-px0 pb4">
          <h1>Transparent and Straightforward Pricing</h1>
          <p className="large-p-tag max-width-2 mx-auto mt1">
            Uploading is always free to filmmakers - your festival only pays for files that you download.
          </p>
        </div>
      </Background>
      <Plans />
      {showModal && <FestivalsSignupModal onClose={() => setShowModal(false)} />}
    </Layout>
  )
}

export default Pricing
