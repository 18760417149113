import React, { useState } from "react"
import { css } from "@emotion/core"
import plans from "@data/pricing/festivals"
import FestivalsSignupModal from "@page-components/festivals/signup-modal"

const Plans = () => {
  const [showModal, setShowModal] = useState(false)

  return (
    <section className="px2 md-px3 mt3">
      <div className="flex flex-wrap items-stretch max-width-5 mx-auto mb3">
        {plans.map(plan => (
          <div
            key={plan.label}
            className="col-12 sm-col-10 md-col-8 lg-col-6 mx-auto px1 lg-mb0 mb4 center max-width-2">
            <div
              css={css`
                position: relative;
                height: 100%;
                border-radius: 8px;
                box-shadow: 0 9px 25px 0 rgba(56, 62, 67, 0.12);
                overflow: hidden;
              `}
              className="bg-white p3">
              <div
                css={css`
                  z-index: 1;
                  position: absolute;
                  top: 0;
                  left: 0;
                  right: 0;
                  bottom: 0;
                `}
              />

              <div
                css={css`
                  z-index: 2;
                  position: relative;
                `}>
                <p className="mb3">{plan.description}</p>
                <div className="mb3">
                  <strong
                    className="block center"
                    css={css`
                      font-size: 38px;
                    `}>
                    {`${plan.price}`}
                  </strong>
                </div>
                <button onClick={() => setShowModal(true)} tabIndex="-1" className="col-12 block">
                  Sign up For CineSend Festivals
                </button>
                <ul className="pl0">
                  {plan.features.map(item => (
                    <li className="list-style-none flex items-center py1" key={item}>
                      <span className="material-icons mr1">check</span>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
      <p className="center light py2">
        All fees, including monthly subscription fees, are subject to applicable local taxes. 1TB minimum purchase.
      </p>
      {showModal && <FestivalsSignupModal onClose={() => setShowModal(false)} />}
    </section>
  )
}

export default Plans
